import React, { Component } from 'react'
import moment from 'moment';
import { CalendarSegmentContent } from './CalendarSegmentContent';
import { CalendarSegmentHeader } from './CalendarSegmentHeader';
import { MobileCalendarSegmentContent } from './MobileCalendarSegmentContent';
import { WeekPicker } from './WeekPicker';
import CheckListForm from '../components/control/partials/CheckListForm';

export class CalendarCheckList extends Component {

    getCurrentMonths = () => {
        let months = []

        let start = moment().startOf('year')
        let current = null;

        for (let i = 1; i <= 6; i++) {
            if (i === 1) {
                current = start
                start = current;
                months.push(current)
            } else {
                current = moment(start).add(1, 'M')
                start = current;
                months.push(current)
            }
        }

        return months
    }

    getCurrentWeek = () => {
        let currentDate = moment();
        let weekStart = currentDate.clone().startOf('week');

        let days = [];
        for (let i = 1; i <= 7; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        };
        console.log(days);
        return days
    }

    nextMonths = () => {

        let months = []
        let start = moment(this.state.currentMonths[5]).add(1, 'M')
        let current = null;

        for (let i = 1; i <= 6; i++) {
            if (i === 1) {
                current = start
                start = current;
                months.push(current)
            } else {
                current = moment(start).add(1, 'M')
                start = current;
                months.push(current)
            }
        }
        this.setState({
            currentMonths: months
        })

    }

    prevMonths = () => {

        let months = []
        let start = moment(this.state.currentMonths[0]).subtract(6, 'M')
        let current = null;

        for (let i = 1; i <= 6; i++) {
            if (i === 1) {
                current = start
                start = current;
                months.push(current)
            } else {
                current = moment(start).add(1, 'M')
                start = current;
                months.push(current)
            }
        }
        this.setState({
            currentMonths: months
        })

    }

    state = {
        month: moment(new Date()).format('MMMM'),
        currentMonth: new Date(),
        year: moment(new Date()).format('yyyy'),
        currentDate: moment(),
        currentYear: new Date(),
        currentWeek: this.getCurrentWeek(),
        currentWeekDay: (moment().format('dddd').toLowerCase() === 'saturday' || moment().format('dddd').toLowerCase() === 'sunday' ? 'monday' : moment().format('dddd').toLowerCase()),
        showWeekPicker: false,
        currentMonths: this.getCurrentMonths(),
        selected: null,
        selectedDay: null
    }


    week = [
        {
            id: 1,
            title: 'MON',
            name: 'monday',
            day: 20201228
        },
        {
            id: 2,
            title: 'TUE',
            name: 'tuesday',
            day: 20201228
        },
        {
            id: 3,
            title: 'WED',
            name: 'wednesday',
            day: 20201228
        },
        {
            id: 4,
            title: 'THU',
            name: 'thursday',
            day: 20201228
        },
        {
            id: 5,
            title: 'FRI',
            name: 'friday',
            day: 20201228
        },
    ]


    nextWeek = () => {
        this.setState({
            currentDate: moment(this.state.currentDate).add(7, 'days'),
            month: moment(this.state.currentDate).add(7, 'days').format('MMM D'),
            year: moment(this.state.currentDate).add(7, 'days').format('yyyy')
        })

        let week = 0;
        if (this.state.currentDate.day() === 0) {
            week = moment(this.state.currentDate)
        } else {
            week = moment(this.state.currentDate).add(7, 'days');
        }
        let weekStart = week.clone().startOf('week');
        let days = [];

        for (let i = 1; i <= 7; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        };

        this.setState({
            currentWeek: days
        })
    }

    prevWeek = () => {
        this.setState({
            currentDate: moment(this.state.currentDate).subtract(7, 'days'),
            month: moment(this.state.currentDate).subtract(7, 'days').format('MMM D'),
            year: moment(this.state.currentDate).subtract(7, 'days').format('yyyy')
        })
        let week = moment(this.state.currentDate).subtract(7, 'days');
        let weekStart = week.clone().startOf('week');
        let days = [];

        for (let i = 1; i <= 7; i++) {
            days.push(moment(weekStart).add(i, 'days'));
        };

        this.setState({
            currentWeek: days
        })
    }

    // showView = () => {
    //     switch (this.props.view) {
    //         case 'month':
    //             return this.showMonth()
    //         case 'year':
    //             return this.showYear();
    //         default:
    //             return this.showWeek()
    //     }
    // }

    setSelected = (id, day) => {
        this.setState({
            selected: id,
            selectedDay: day
        })
    }

    showYear = () => {

        return (
            <React.Fragment
            >
                <div className="calendar-content-header">
                    {
                        this.state.currentMonths?.map((month) => (
                            <div key={month} className="header-col">
                                <p className="header-year">{moment(month).format('MMMM')}</p>
                                <p className="header-year-mobile">{moment(month).format('MMMM').substring(0, 3)}</p>
                            </div>
                        ))
                    }

                </div>

                <div className="calendar-content-items">
                    <div className="item-row">
                        {
                            this.state.currentMonths?.map((month) => {
                                return (
                                    <div className="item-col">

                                        {
                                            this.props.data[moment(month).format('MMMM').toLowerCase()].map((item) => {
                                                return (
                                                    <div className="segment-content">
                                                        <div className="segment-content-item">
                                                            <div className="leave-days">
                                                                <div className="number">
                                                                    {
                                                                        item.extraData
                                                                    }
                                                                </div>
                                                                <div className="info">
                                                                    <div className="info-img">
                                                                        <img src={item.img} alt="" />
                                                                    </div>
                                                                    <p className="info-name">{item.name}</p>
                                                                    <p>{item.type}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }




                                    </div>
                                )

                            })
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    showWeek = () => (
        <React.Fragment>
            <div className="calendar-content-header">
                {
                    this.state.currentWeek.map((day) => (
                        <div key={day} className="header-col">
                            <p>{moment(day).format('ddd D')}</p>
                        </div>
                    ))
                }
            </div>
            <div className="calendar-content-items">
                <div className="item-row">
                    {
                        this.props.week?.map((day) => (
                            <div key={day.id} className="item-col">
                                {
                                    this.props.segments?.map((segment) => {
                                        return (
                                            <div key={segment.name} className="segment">
                                                <CalendarSegmentHeader
                                                    data={this.props.data}
                                                    segment={segment}
                                                    day={day}
                                                />
                                                <CalendarSegmentContent
                                                    showDetails={this.props.showDetails}
                                                    data={this.props.data}
                                                    segment={segment}
                                                    day={day}
                                                    selected={this.state.selected}
                                                    selectedDay={this.state.selectedDay}
                                                    setSelected={this.setSelected}
                                                />


                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </React.Fragment>
    )


    showMonth = () => {
        const params = `${this.state.currentMonth}, ${this.state.year}`
        const date = new Date(params).getDate();
        let weekDay = new Date(params).getDay() + 1;
        const days = moment(params).daysInMonth();
        const x = date % 7;

        for (let i = 0; i < x; i++) {
            weekDay--;
            if (weekDay === 0) weekDay = 7
        }
        return (
            <React.Fragment>
                <div className="calendar-content-header">
                    {
                        this.week.map((day) => (
                            <div key={day.id} className="header-col">
                                <p>{day.title}</p>
                            </div>
                        ))
                    }
                </div>
                <div className="calendar-content-items month">
                    {
                        [1, 2, 3, 4, 5].map((weekN) => (
                            <div key={weekN} className="item-row">
                                {
                                    this.week.map((day) => {
                                        let currentDay = 0
                                        if (weekDay === 6 || weekDay === 7) {
                                            currentDay = (7 - (weekDay - 1) + day.id + ((weekN - 2) * 7)) + 7;
                                        } else {
                                            currentDay = 7 - (weekDay - 1) + day.id + ((weekN - 2) * 7);
                                        }
                                        const currentDate = `${moment(this.state.currentMonth).format('MMMM')} ${currentDay}, ${moment(this.state.currentYear).format('YYYY')}`
                                        return (
                                            <div key={day.id} className={currentDay <= 0 || currentDay > days ? "month-day disappear" : "month-day"}>
                                                {currentDay <= 0 || currentDay > days ? '' :
                                                    <React.Fragment>
                                                        <div
                                                            onClick={() => { this.props.showDayDetails(this.datos[currentDay - 1], currentDate) }}
                                                            className="current-month-day">
                                                            <p className="current-month-day-number">{currentDay}</p>
                                                            <div className="current-month-day-segments">
                                                                {
                                                                    this.datos[currentDay - 1] &&
                                                                    <div className={
                                                                        this.datos[currentDay - 1].am.num === this.datos[currentDay - 1].am.total ? 'current-month-day-segment green' :

                                                                            this.datos[currentDay - 1].am.num > this.datos[currentDay - 1].am.total ? 'current-month-day-segment red' :
                                                                                'current-month-day-segment gray'

                                                                    }>
                                                                        <p>AM</p>
                                                                        <p>{this.datos[currentDay - 1].am.num}/{this.datos[currentDay - 1].am.total}</p>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.datos[currentDay - 1] &&
                                                                    <div
                                                                        className={
                                                                            this.datos[currentDay - 1].pm.num === this.datos[currentDay - 1].am.total ? 'current-month-day-segment green' :

                                                                                this.datos[currentDay - 1].pm.num > this.datos[currentDay - 1].am.total ? 'current-month-day-segment red' :
                                                                                    'current-month-day-segment gray'

                                                                        }
                                                                    >
                                                                        <p>PM</p>
                                                                        <p>{this.datos[currentDay - 1].pm.num}/{this.datos[currentDay - 1].pm.total}</p>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        )
                                    }

                                    )
                                }
                            </div>
                        ))
                    }
                </div>
            </React.Fragment>
        );
    }

    datos = [
        {

            am: {
                num: 10,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]
            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }

                ]

            }
        },
        {
            am: {
                num: 3,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]
            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }
                ]

            }
        },
        {
            am: {
                num: 3,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]
            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }
                ]

            }
        },
        {
            am: {
                num: 3,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]
            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }
                ]

            }
        },
        {
            am: {
                num: 11,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]

            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }
                ]


            }
        },
        {
            am: {
                num: 10,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]
            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }
                ]

            }
        },
        {
            am: {
                num: 3,
                total: 10,
                data: [
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    },
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    }
                ]
            },
            pm: {
                num: 12,
                total: 10,
                data: [
                    {
                        name: 'Jane H. Doe',
                        img: './assets/child_2.png',
                        extraData: 24
                    },
                    {
                        name: 'Charlie Bloom',
                        img: './assets/child_1.png',
                        extraData: 17
                    }
                ]

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 10,
                total: 10

            }
        },
        {
            am: {
                num: 10,
                total: 10
            },
            pm: {
                num: 10,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
        {
            am: {
                num: 3,
                total: 10
            },
            pm: {
                num: 12,
                total: 10

            }
        },
    ]


    nextMonth = () => {
        this.setState({
            currentMonth: moment(this.state.currentMonth).startOf('month').add(1, 'M'),
        })
    }

    prevMonth = () => {
        this.setState({
            currentMonth: moment(this.state.currentMonth).startOf('month').subtract(1, 'M'),
        })
    }

    apply = (currentWeek) => {
        this.setState({
            showWeekPicker: false,
            currentWeek
        })
    }

    render() {
        return (
            <div id="calendar-component" className={this.props.view === 'year' && "calendar-width"}>
                <div className="calendar-actions">

                    <div className="picker">
                            <div className="picker-range-selector">
                                <i onClick={this.prevWeek} className="material-icons">
                                    chevron_left
                                    </i>
                                <p>{moment(this.state
                                    .currentWeek[0]).format('MMM DD')}-{moment(this.state.currentWeek[6]).format('MMM DD')}/{this.state.year}</p>
                                <span onClick={this.nextWeek} className="material-icons">
                                    chevron_right
                                    </span>
                            </div>


                        {
                            this.props.view === 'month' &&
                            <div className="picker-range-selector">
                                <span onClick={this.prevMonth} className="material-icons">
                                    chevron_left
                                    </span>

                                <p>{moment(this.state.currentMonth).format('MMMM')} {moment(this.state.currentMonth).format('YYYY')}</p>

                                <span onClick={this.nextMonth} className="material-icons">
                                    chevron_right
                                    </span>
                            </div>
                        }

                        {
                            this.props.view === 'year' &&
                            <div className="picker-range-selector">
                                <span onClick={this.prevMonths} className="material-icons">
                                    chevron_left
                                    </span>

                                <p>{moment(this.state.currentMonths[0]).format('MMMM')} - {moment(this.state.currentMonths[5]).format('MMMM')} / {moment(this.state.currentMonths[5]).format('YYYY')}</p>

                                <span onClick={this.nextMonths} className="material-icons">
                                    chevron_right
                                    </span>
                            </div>
                        }
                    </div>

                </div>



                {/* <div className={(this.props.view === 'month' || this.props.view === 'year') ? 'calendar-content display-month' : 'calendar-content'}>
                    {this.showView()}
                </div> */}

                    <div className="calendar-content-mobile-checklist">
                        <div className="calendar-content-mobile-checklist-header">
                            {
                                this.state.currentWeek.map((day, index) => {
                                    return (
                                        <React.Fragment>
                                        <div className="mobile-week-day" onClick={() => this.setState({ currentWeekDay: moment(day).format('dddd').toLowerCase() })}>
                                            <p>{moment(day).format('ddd')}</p>
                                            <p className={moment(day).format('dddd').toLowerCase() === this.state.currentWeekDay ? 'selected-day' : this.props.segments[index].absent ? ' red' : this.props.segments[index].notAvailable ? 'yellow' : ''}>{moment(day).format('D')}</p>
                                            {
                                                this.props.data.displayId === 6 &&
                                                <div className="week-day-wrapper">
                                                    {
                                                        this.props.segments.map(segment => {
                                                            return (
                                                                <div className={
                                                                    !segment.content || !segment.content[this.props.week[index].name].num ? "week-day-circle circle-blue" :
                                                                        segment.content[this.props.week[index].name].num === segment.content[this.props.week[index].name].total ? "week-day-circle circle-green" :
                                                                            segment.content[this.props.week[index].name].num > segment.content[this.props.week[index].name].total ? "week-day-circle circle-red" : "week-day-circle circle-empty"
                                                                }>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                        
                                        </React.Fragment>
                                    )
                                })
                            }
                            
                        </div>
                        <div className="mobile-week-footer">
                                            <div className="item absent">
                                                <div className="circle"></div>
                                                <p>Absent</p>
                                            </div>
                                            <div className="item not-available">
                                                <div className="circle"></div>
                                                <p>Not available</p>
                                            </div>
                                        </div>
                        <div className="calendar-content-mobile-segments">
                        <React.Fragment>
                                  <CheckListForm
                                        name = "Sleeping time"
                                        order = {1}
                                        data = {[]}
                                    />
                                    <CheckListForm
                                        name = "Eating time"
                                        order = {2}
                                        data = {[]}
                                    />
                                    <CheckListForm
                                        name = "Behavior"
                                        order = {3}
                                        data = {[]}
                                    />
                            </React.Fragment>
                        </div>
                    </div>
            </div>
        )
    }
}

