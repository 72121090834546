import { Component } from "react";
import moment from 'moment';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i <= 5; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

export class WeekPicker extends Component {
    state = {
        hoverRange: undefined,
        selectedDays: [],
        currentWeek: []
    };

    handleDayChange = date => {
        this.setState({
            selectedDays: getWeekDays(getWeekRange(date).from),
        });
        let days = getWeekDays(getWeekRange(date).from)
        days.shift();
        this.setState({
            currentWeek: days
        })
    };

    handleDayEnter = date => {
        this.setState({
            hoverRange: getWeekRange(date),
        });
    };

    handleDayLeave = () => {
        this.setState({
            hoverRange: undefined,
        });
    };

    handleWeekClick = (weekNumber, days, e) => {
        this.setState({
            selectedDays: days,
        });
    };

    render() {
        const { hoverRange, selectedDays } = this.state;

        const daysAreSelected = selectedDays.length > 0;

        const modifiers = {
            hoverRange,
            selectedRange: daysAreSelected && {
                from: selectedDays[1],
                to: selectedDays[4],
            },
            hoverRangeStart: hoverRange && hoverRange.from,
            hoverRangeEnd: hoverRange && hoverRange.to,
            selectedRangeStart: daysAreSelected && selectedDays[1],
            selectedRangeEnd: daysAreSelected && selectedDays[4],
        };

        const month = moment(this.props.month).format('MMMM')
        const currentMonth = `${month}, ${this.props.year}`


        return (
            <div className="week-picker">
                <DayPicker
                    month={new Date(currentMonth)}
                    selectedDays={selectedDays}
                    modifiers={modifiers}
                    onDayClick={this.handleDayChange}
                    onDayMouseEnter={this.handleDayEnter}
                    onDayMouseLeave={this.handleDayLeave}
                    onWeekClick={this.handleWeekClick}
                    disabledDays={{ daysOfWeek: [0, 6] }}
                />
                <div className="apply">
                    <button onClick={() => { this.props.apply(this.state.currentWeek) }}>Apply</button>
                </div>
            </div>
        );
    }
}