import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

class Table extends Component {


    selectRow = (item, key) => {
        document.querySelector(`#tr-${key}`).classList.toggle('toggle')
        console.log(`tr-${key}`)
    }

    printRows = () => {
        if (this.props.data.length !== 0) {
            return (
                <tbody>
                        {this.props.data.map((item, key) => (
                            <tr>
                                {this.printTd(key)}
                                <td>
                                    {this.props.download && <button className="ghost"><i className="material-icons download">get_app</i></button>}
                                    {this.props.view ? <button className="ghost" onClick={()=>this.props.viewFunction(item.id)}><i class="material-icons view">remove_red_eye</i></button>: null}
                                    {this.props.edit ? <button className="ghost" onClick={()=>this.props.editFunction(item.id)}><i class="material-icons edit">edit</i></button>: null}
                                    {this.props.delete ? <button className="ghost" onClick={()=>this.props.deleteFunction(item.id)}><i class="material-icons delete">delete</i></button> : null}
                                </td>
                            </tr>
                        ))}
                </tbody>
            );
        } else {
            return (
                <tbody>
                    <tr>
                        <td colSpan={this.props.columns.length + 1} rowSpan="8" className="error">No records</td>
                    </tr>
                </tbody>
            );
        }
    }

    printTd = (i) => {
        return (
            <React.Fragment>
                {this.props.columns.map(column => (
                    <React.Fragment>
                        {column.bool ?
                            <td>
                                {
                                    (this.props.data[i][column.name] ? <i className="material-icons green">done</i> : <i className="material-icons red">close</i>)
                                }
                            </td>
                            :
                            <td>
                                {
                                    ((this.props.data[i][column.name] ?
                                        (this.props.data[i][column.name].length !== 1 ?
                                            this.props.data[i][column.name] :
                                            (this.props.data[i][column.name].map(element => (
                                                <React.Fragment>
                                                    {element.image ? <div className="image-container"><img src={element.image} /></div> : null}
                                                    <div className="text-container">
                                                        {element.principal ? element.principal : ''}
                                                        <span>{element.sub ? element.sub : ''}</span>
                                                    </div>
                                                </React.Fragment>
                                            )))) : ''))
                                }
                            </td>
                        }
                    </React.Fragment>
                ))}
            </React.Fragment>
        );

    }

    printColumns = () => {
        if (this.props.columns.length !== 0) {
            return (
                this.props.columns.map(column => (
                    <th>{column.title}</th>
                ))
            );

        }
    }

    render() {
        return (
            <React.Fragment>
                <div id="table_component">
                    <table>
                        <thead>
                            <tr>
                                {this.printColumns()}
                                <th className="btn-container"></th>
                            </tr>
                        </thead>
                        {this.printRows()}
                    </table>
                </div>
            </React.Fragment>
        );
    }
}

export default Table;