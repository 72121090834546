import React, { Component } from 'react';
import {
    Redirect
} from "react-router-dom";

class DocumentsRoute extends Component {


    render() {
        return (
            <React.Fragment>
                <Redirect to="/documents/overview" />
            </React.Fragment>
        );
    }
}

export default DocumentsRoute;