import { Component } from "react";

export class CalendarSegmentHeader extends Component {


    render() {
        return (
            <div className="segment-header green"
            >

                {
                    (this.props.data.displayId === 1 || this.props.data.displayId === 3 || this.props.data.displayId === 4) &&
                    <div>
                        <p>{this.props.segment.title}</p>
                    </div>
                }
                {
                    this.props.data.displayId === 2 &&
                    <div className="segment-waiting-list">
                        <p>{this.props.segment.title}</p>
                        <p>{this.props.segment.content[this.props.day.name].total}</p>
                    </div>
                }

                {
                    this.props.data.displayId === 5 &&
                    <div className="segment-waiting-list">
                        <p>{this.props.segment.title}</p>

                    </div>
                }
            </div>
        )
    }
}