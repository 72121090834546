import React, { Component } from "react";
import { Calendar } from "../../partials/Calendar";
import { CalendarCheckList } from "../../partials/CalendarCheckList";

export class WaitingListOverview extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        segments: [{
                        day: 1,
                        absent: false,
                        notAvailable: false,
                    },{
                        day: 2,
                        absent: false,
                        notAvailable: false,
                    },{
                        day: 3,
                        absent: false,
                        notAvailable: false,
                    }, {
                        day: 4,
                        absent: true,
                        notAvailable: false,
                    },{
                        day: 5,
                        absent: false,
                        notAvailable: false,
                    }, {
                        day: 6,
                        absent: false,
                        notAvailable: true,
                    },{
                        day: 7,
                        absent: false,
                        notAvailable: true,
                    }
            ],
        dataInfo: {
            displayId: 2,
            monday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
            },
            tuesday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
            },
            wednesday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
            },
            thursday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ]
            },
            friday: {
                am: [
                    {
                        id: 5,
                        name: 'Misael',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: './assets/child_1.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: './assets/child_2.png',
                        extraData: '17',
                        sub: 'Stakeholder'
                    }
                ]
            }
        },
        week: [
            {
                id: 1,
                title: 'MON',
                name: 'monday',
                day: 20201228
            },
            {
                id: 2,
                title: 'TUE',
                name: 'tuesday',
                day: 20201228
            },
            {
                id: 3,
                title: 'WED',
                name: 'wednesday',
                day: 20201228
            },
            {
                id: 4,
                title: 'THU',
                name: 'thursday',
                day: 20201228
            },
            {
                id: 5,
                title: 'FRI',
                name: 'friday',
                day: 20201228
            },
            {
                id: 6,
                title: 'SAT',
                name: 'saturday',
                day: 20201228
            },
            {
                id: 7,
                title: 'SAT',
                name: 'sunday',
                day: 20201228
            },
        ]
    }

    users = [
        {
            id: 2,
            firstName: 'Susan',
            lastName: 'G. Smith',
            prefName: 'Susan G. Smith',
            img: './assets/child_2.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            age: "24",
            location: "Location name 1",
            comment: '2020/07/05',
            preferredStart: '2020/07/05',
            priority: 'Stakeholder',
            phone: '(12) 3456 7890',
        },

        {
            id: 5,
            firstName: 'John',
            lastName: 'H. Doe',
            prefName: 'John H. Doe',
            img: './assets/child_1.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            age: "17",
            location: "Location name 2",
            comment: '2020/07/05',
            preferredStart: '2020/07/05',
            priority: 'Stakeholder',
            phone: '(08) 8968 6281',
        },
        {
            id: 3,
            firstName: 'Susan',
            lastName: 'G. Smith',
            prefName: 'Susan G. Smith',
            img: './assets/child_2.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            age: "24",
            location: "Location name 1",
            comment: '2020/07/05',
            preferredStart: '2020/07/05',
            priority: 'Stakeholder',
            phone: '(12) 3456 7890',
        },

        {
            id: 6,
            firstName: 'John',
            lastName: 'H. Doe',
            prefName: 'John H. Doe',
            img: './assets/child_1.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            age: "17",
            location: "Location name 2",
            comment: '2020/07/05',
            preferredStart: '2020/07/05',
            priority: 'Stakeholder',
            phone: '(08) 8968 6281',
        }
    ]

    displayAsidebar = () => {
        this.setState({
            display_asidebar: true
        })
    }

    showAsideBar = () => {
        if (!this.state.asiderbar_details) {
            return (
                <div className={this.state.display_asidebar ? "aside-bar popup active" : "aside-bar popup"}>
                    <div className="container">
                        <div className="header">
                            <h3><i className="material-icons edit">filter_alt</i> FILTERS</h3>
                            <button id="close_popup" onClick={() => this.setState({ display_asidebar: false })}><i className="material-icons">close</i></button>
                        </div>
                        <div className="filters">
                            <div className="group-input">
                                <label htmlFor="">LOCATION</label>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city1" value="1" />
                                    <label htmlFor="city1">Location's name 1</label>
                                </div>
                                <div className="group-radio">
                                    <input type="radio" name="city" id="city2" value="2" />
                                    <label htmlFor="city2">Location's name 2</label>
                                </div>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">GROUP</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a group...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">PRODUCT</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose a product...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input">
                                <label htmlFor="">LIFECYCLE PHASE</label>
                                <select name="location" id="location-select">
                                    <option value="0" selected>Choose phase...</option>
                                    <option value="1">Group's name 1</option>
                                    <option value="2">Group's name 2</option>
                                </select>
                            </div>
                            <div className="group-input no-padding-bottom">
                                <div className="subtitle">AGE</div>
                            </div>
                            <div className="group-input col-2 no-padding-top">
                                <label htmlFor="">From</label>
                                <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />

                                <label htmlFor="" className="center">to</label>
                                <input type="number" name="" defaultValue="test@wondergarden.com" id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">PLANNING BY</label>
                                <select onChange={this.planningBy} name="location" id="location-select">
                                    <option value="week" selected>Week</option>
                                    <option value="month">Month</option>
                                </select>
                            </div>
                            <div className="btn-container">
                                <button className="principal">Apply</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            console.log(this.state);
            return (
                <div className="aside-bar popup active info">
                    <div className="container aside-bar-info-active">
                        <div className="header">
                            <div className="btn-container">
                                <button className="left" onClick={() => this.setState({ asiderbar_details: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                            </div>
                        </div>
                        <div className="img-container">
                            <div className="circle">
                                <img src={this.state.user.img} alt="" />
                            </div>
                        </div>
                        <div className="subtitle">INFO</div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">First Name</label>
                                <input type="text" name="" value={this.state.user.firstName} disabled id="name" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Last Name</label>
                                <input type="text" value={this.state.user.lastName} disabled id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Pref Name</label>
                                <input type="text" value={this.state.user.prefName} disabled id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Age</label>
                                <input type="text" value={this.state.user.age} disabled id="text" />
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="group-input">
                                <label htmlFor="">Priority</label>
                                <input type="text" value={this.state.user.priority} disabled id="text" />
                            </div>
                            <div className="group-input">
                                <label htmlFor="">Preferred start</label>
                                <input type="text" value={this.state.user.preferredStart} disabled id="text" />
                            </div>
                        </div>

                        <div className="group-input">
                            <label htmlFor="">Location</label>
                            <input type="text" value={this.state.user.location} disabled id="text" />
                        </div>
                        <div className="group-input">
                            <label htmlFor="">Comment</label>
                            <input type="text" value={this.state.user.comment} disabled id="text" />
                        </div>

                        <div className="group-input">
                            <label htmlFor="">Caretakers</label>
                            {
                                this.state.user.caretakers.map((caretaker) => (
                                    <p className="caretaker-items"> <span className="material-icons view">remove_red_eye</span> {caretaker.name}</p>
                                ))
                            }
                        </div>

                        <div className="group-input">
                            <label htmlFor="">Phone</label>
                            <input type="text" value={this.state.user.phone} disabled id="text" />
                        </div>

                        <div className="col-2 waiting-list-btn">
                            <button className="red">Delete from waiting list</button>
                            <button className="primary">Add planning</button>
                        </div>

                        <div className="waiting-list-btn-block">
                            <button className="green">Go to child details</button>
                        </div>

                        <div className="btn-container dissapear">
                            <button className="principal">Save</button>
                        </div>
                    </div>
                </div>
            )
        }
    }

    showDetails = (id) => {
        console.log(this.users.find(user => user.id === id));
        this.setState({
            asiderbar_details: true,
            user: this.users.find(user => user.id === id)
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="content">
                    <CalendarCheckList
                        title="Waiting List Overview"
                        subtitle="Planning Tools > Waiting List Overview"
                        data={this.state.dataInfo}
                        week={this.state.week}
                        segments={this.state.segments}
                        showDetails={this.showDetails}
                        displayAsidebar={this.displayAsidebar}
                    />
                </div>
            </React.Fragment>
        )
    }
}