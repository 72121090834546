import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class InvoicesFamilyPanel extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 14,
        total_pages: 3,
        display_asidebar: false
    }

    render() {
        return (
            <React.Fragment>
                <div className="content">
                    <div className="tab warning">
                        Next payment due: <b> 01/03/2020</b> <br/>
                        Next payment amount: <b> $150</b>
                    </div>
                    
                    <Table
                        data={[
                            {
                                id: 1,
                                period: 12,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 2,
                                period: 11,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 3,
                                period: 10,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 4,
                                period: 9,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 5,
                                period: 8,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 6,
                                period: 7,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 1,
                                period: 12,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 2,
                                period: 11,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 3,
                                period: 10,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 4,
                                period: 9,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 5,
                                period: 8,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                            {
                                id: 6,
                                period: 7,
                                amount: "$75.50",
                                status: 'Payed'
                            },
                        ]}
                        columns={[
                            {
                                title: "#",
                                name: "id",
                                bool: false
                            },
                            {
                                title: "Period",
                                name: "period",
                                bool: false
                            },
                            {
                                title: "Amount",
                                name: "amount",
                                bool: false
                            },
                            {
                                title: "Status",
                                name: "status",
                                bool: false
                            },
                        ]}
                        viewFunction={''}
                        view={false}
                        edit={false}
                        delete={false}
                        download = {true}
                        editFunction={(id)=>{this.props.history.push(`/locations/overview/edit/${id}`)}}
                        deleteFunction=""
                        totalElements="47"
                    />
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default InvoicesFamilyPanel;