import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";
import Table from '../../partials/Table';
import Pagination from '../../partials/Pagination';

class DocumentsFamilyPanel extends Component {
    state = {
        page: 1,
        per_page: 15,
        total_elements: 14,
        total_pages: 3,
        display_asidebar: false
    }

    render() {
        return (
            <React.Fragment>
                <div className="content">
                    <Table
                        data={[
                            {
                                id: 1,
                                name: "Document's name 1"
                            },
                            {
                                id: 2,
                                name: "Document's name 2"
                            },
                            {
                                id: 3,
                                name: "Document's name 3"
                            },
                            {
                                id: 4,
                                name: "Document's name 4"
                            },
                            {
                                id: 5,
                                name: "Document's name 5"
                            },
                            {
                                id: 6,
                                name: "Document's name 6"
                            },
                            {
                                id: 1,
                                name: "Document's name 1"
                            },
                            {
                                id: 2,
                                name: "Document's name 2"
                            },
                            {
                                id: 3,
                                name: "Document's name 3"
                            },
                            {
                                id: 4,
                                name: "Document's name 4"
                            },
                            {
                                id: 5,
                                name: "Document's name 5"
                            },
                            {
                                id: 6,
                                name: "Document's name 6"
                            }
                        ]}
                        columns={[
                            {
                                title: "Name",
                                name: "name",
                                bool: false
                            }
                        ]}
                        viewFunction={''}
                        view={false}
                        edit={false}
                        delete={false}
                        download = {true}
                        editFunction={(id)=>{this.props.history.push(`/locations/overview/edit/${id}`)}}
                        deleteFunction=""
                        totalElements="47"
                    />
                    <Pagination
                        page={this.state.page}
                        per_page={this.state.per_page}
                        total_elements={this.state.total_elements}
                        total_pages={this.state.total_pages}
                        change_page={page => this.setState({ page })}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default DocumentsFamilyPanel;