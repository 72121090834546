import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Header from './partials/Header';
import Categories from './partials/Categories'
import Home from './Home';
import DocumentsFamilyPanel from './components/documents/Documents';
import DocumentsRoute from './components/documents/DocumentsRoute';
import InvoicesFamilyPanel from './components/documents/Invoices';
import { Messages } from './components/family-panel/Messages';
import { LunchMenu } from './components/family-panel/LunchMenu';
import { Activities } from './components/family-panel/Activities';
import CalendarRoute from './components/calendar/CalendarRoute';
import { ChildrenOverviewPT } from './components/planning-tools/ChildrenOverviewPT';
import { WaitingListOverview } from './components/planning-tools/WaitingListOverview';

class RouterApp extends Component {

  render() {
    return (
      <div>
        <Router>
          <Header />
          <Categories />
          <div id="todo">
            <Switch>
            <Route path="/child-info" component={WaitingListOverview}/>

            <Route path="/calendar/lunch-menu" component={LunchMenu}/>
            <Route path="/calendar/activities" component={Activities}/>
            <Route path="/calendar/teachers" component={ChildrenOverviewPT}/>
            <Route path="/calendar" component={CalendarRoute}/>

            <Route path="/messages" component={Messages}/>
                 
            <Route path="/documents/invoices" component={InvoicesFamilyPanel}/>
            <Route path="/documents/overview" component={DocumentsFamilyPanel}/>
            <Route path="/documents" component={DocumentsRoute}/>

              <Route path="/" component={Home}/>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default RouterApp;