import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Calendar } from '../../partials/Calendar'

export class ChildrenOverviewPT extends Component {

    state = {
        display_asidebar: false,
        asiderbar_details: false,
        showDayDetail: false,
        view: 'week',
        selectedDay: {},
        user: {

        },
        segments: [
            {
                content: {
                    monday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    tuesday: {
                        color: 'gray',
                        num: 3,
                        total: 10,
                    },
                    wednesday: {
                        color: 'green',
                        num: 3,
                        total: 10,
                    },
                    thursday: {
                        color: 'green',
                        num: 5,
                        total: 10,
                    },
                    friday: {
                        color: 'green',
                        num: 8,
                        total: 10,
                    },
                    saturday: {
                        color: 'green',
                        num: 5,
                        total: 10,
                    },
                    sunday: {
                        color: 'green',
                        num: 8,
                        total: 10,
                    }
                },
                name: 'am',
                title: 'AM'
            },
            {

                content: {
                    monday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    tuesday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    wednesday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    thursday: {
                        color: 'red',
                        num: 12,
                        total: 10,
                    },
                    friday: {
                        color: 'green',
                        num: 10,
                        total: 10,
                    },
                    saturday: {
                        color: 'green',
                        num: 5,
                        total: 10,
                    },
                    sunday: {
                        color: 'green',
                        num: 8,
                        total: 10,
                    }
                },
                name: 'pm',
                title: 'PM'
            },
        ],
        data: {
            displayId: 1,
            monday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 7,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
            },
            tuesday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ]
            },
            wednesday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
            },
            thursday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ]
            },
            friday: {
                am: [
                    {
                        id: 5,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 2,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ],
                pm: [
                    {
                        id: 6,
                        name: 'John H. Doe',
                        img: '/assets/caretaker_1.png',
                        extraData: '17',
                    },
                    {
                        id: 3,
                        name: 'Susan G. Smith',
                        img: '/assets/caretaker_2.png',
                        extraData: '17',
                    }
                ]
            },
            saturday: {
                am: [],
                pm: []
            },
            sunday: {
                am: [],
                pm: []
            }
        },
        week: [
            {
                title: 'MON',
                name: 'monday',
                day: 20201228
            },
            {
                title: 'TUE',
                name: 'tuesday',
                day: 20201228
            },
            {
                title: 'WED',
                name: 'wednesday',
                day: 20201228
            },
            {
                title: 'THU',
                name: 'thursday',
                day: 20201228
            },
            {
                title: 'FRI',
                name: 'friday',
                day: 20201228
            },
            {
                id: 6,
                title: 'SAT',
                name: 'saturday',
                day: 20201228
            },
            {
                id: 7,
                title: 'SUN',
                name: 'sunday',
                day: 20201228
            }
        ]
    }


    users = [
        {
            id: 2,
            firstName: 'Susan',
            lastName: 'G. Smith',
            prefName: 'Susan G. Smith',
            img: './assets/child_2.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "24",
            group: "Group's name 1",
            address: '61 CliftonStreet , NAGAMBIE 3608',
            phone: '(12) 3456 7890',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '07:30 am',
            pickUp: '12:30 pm'
        },

        {
            id: 5,
            firstName: 'John',
            lastName: 'H. Doe',
            prefName: 'John H. Doe',
            img: './assets/child_1.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "17",
            group: "Group's name 2",
            address: '23th street',
            phone: '(08) 8968 6281',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '09:30 am',
            pickUp: '02:30 pm'
        },
        {
            id: 3,
            firstName: 'Susan',
            lastName: 'G. Smith',
            prefName: 'Susan G. Smith',
            img: './assets/child_2.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "24",
            group: "Group's name 1",
            address: '61 CliftonStreet , NAGAMBIE 3608',
            phone: '(12) 3456 7890',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '07:30 am',
            pickUp: '12:30 pm'
        },

        {
            id: 6,
            firstName: 'John',
            lastName: 'H. Doe',
            prefName: 'John H. Doe',
            img: './assets/child_1.png',
            caretakers: [
                {
                    name: 'Sonia C. Eggers'
                },
                {
                    name: 'Harry G. Doe'
                }
            ],
            gender: "Male",
            age: "17",
            group: "Group's name 2",
            address: '23th street',
            phone: '(08) 8968 6281',
            allergies: 'None',
            medConditions: 'None',
            medicines: 'None',
            dropOff: '09:30 am',
            pickUp: '02:30 pm'
        }
    ]



    render() {
        return (
            <React.Fragment>
                {
                    this.state.showDayDetail &&
                    <div className="aside-bar popup active info">
                        <div className="container aside-bar-info-active">
                            <div className="header">
                                <div className="btn-container">
                                    <button className="left" onClick={() => this.setState({ showDayDetail: false })}><i className="material-icons">arrow_back_ios</i> Back</button>
                                </div>
                            </div>

                            <div className="aside-bar-segments">
                                <h2>{this.state.currentDate}</h2>

                                <div className="aside-bar-segment">
                                    <div className={
                                        this.state.selectedDay.am.num === this.state.selectedDay?.am.total ?
                                            "aside-bar-segment-info green" :
                                            this.state.selectedDay.am.num > this.state.selectedDay?.am.total ?
                                                "aside-bar-segment-info red" : 'aside-bar-segment-info empty'
                                    }>
                                        <p>AM {this.state.selectedDay?.am.num}/{this.state.selectedDay?.am.total}</p>
                                    </div>

                                    <div className="aside-bar-segment-items">
                                        {
                                            this.state.selectedDay?.am?.data?.map((item) => (
                                                <div className="aside-bar-segment-item">
                                                    <div className="segment-item-img">
                                                        <img src={item.img} alt="" />
                                                    </div>

                                                    <p>{item.name} <span>({item.extraData})</span></p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="aside-bar-segment">
                                    <div className={
                                        this.state.selectedDay.pm.num === this.state.selectedDay?.pm.total ?
                                            "aside-bar-segment-info green" :
                                            this.state.selectedDay.pm.num > this.state.selectedDay?.pm.total ?
                                                "aside-bar-segment-info red" : 'aside-bar-segment-info empty'
                                    }>
                                        <p>PM {this.state.selectedDay?.pm.num}/{this.state.selectedDay?.pm.total}</p>
                                    </div>
                                    <div className="aside-bar-segment-items">
                                        {
                                            this.state.selectedDay?.pm?.data?.map((item) => (
                                                <div className="aside-bar-segment-item">
                                                    <div className="segment-item-img">
                                                        <img src={item.img} alt="" />
                                                    </div>

                                                    <p>{item.name} <span>({item.extraData})</span></p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="btn-container dissapear">
                                <button className="principal">Save</button>
                            </div>
                        </div>
                    </div>
                }
                <div className="content">
                    <Calendar
                        title="Children Overview"
                        subtitle="Planning Tools > Children Overview"
                        data={this.state.data}
                        segments={this.state.segments}
                        week={this.state.week}
                        showDetails={this.showDetails}
                        view={this.state.view}
                        displayAsidebar={this.displayAsidebar}
                        showDayDetails={this.showDayDetails}
                    />
                </div>
            </React.Fragment>
        )
    }
}
