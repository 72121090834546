import { Component } from "react";

export class CalendarSegmentContent extends Component {

    messagesIcon = () => {
        return (
            <svg width="100%" height="100%" viewBox="0 0 538 540" ><path d="M218.669,-0c-120.562,-0 -218.654,83.549 -218.654,186.268c-0,42.13 16.483,82.465 46.758,115.248l-41.847,42.131c-4.81,4.842 -6.257,12.124 -3.633,18.457c2.59,6.299 8.746,10.431 15.542,10.431l201.834,0c120.563,0 218.655,-83.549 218.655,-186.267c-0,-102.719 -98.092,-186.268 -218.655,-186.268Z"/><path d="M487.762,432.107c30.266,-32.283 46.745,-72.076 46.745,-113.606c-0,-51.945 -25.928,-98.816 -67.325,-132.233c-0.84,119.046 -113.564,215.692 -252.148,215.692l-93.555,0c36.251,59.357 109.73,100.152 194.441,100.152l201.772,0c6.793,0 12.948,-4.072 15.537,-10.316c2.59,-6.242 1.143,-13.42 -3.632,-18.193l-41.835,-41.496Z" /></svg>
        );
      }

    render() {
        return (
            <div className="segment-content">
                {
                    this.props.data.displayId === 1 ?
                        this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                            return (
                                <div key={item.id} className="segment-content-item">
                                    <div className="segment-content-item-image">
                                        <img src={item.img} alt="" />
                                    </div>
                                    <div className="general-item">
                                        <div className="general-item-info">
                                            <p className="name">{item.name}</p>
                                        </div>
                                    </div>
                                    <button className="chat">{this.messagesIcon()}</button>
                                </div>
                            )
                        }
                        )
                        :
                        this.props.data.displayId === 2 ?
                            this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                return (
                                    <div key={item.id} onClick={() => {
                                        this.props.showDetails(item.id)
                                        this.props.setSelected(item.id, this.props.day.name)
                                    }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}>
                                        <div className="segment-content-item-image">
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="general-item">
                                            <div className="general-item-info">
                                                <p className="name">{item.name} <span className="extra-data">({item.extraData})</span></p>
                                            </div>
                                            {
                                                item.sub &&
                                                <div className="general-item-sub">
                                                    <p>{item.sub}</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            )

                            :
                            this.props.data.displayId === 3 ?
                                this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                    return (
                                        <div key={item.id}
                                            onClick={() => {
                                                this.props.showDetails(item.id)
                                                this.props.setSelected(item.id, this.props.day.name)
                                            }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}
                                        >
                                            <div className="lunch-info">
                                                <p className="lunch-time">{item.time}</p>
                                                <p className="lunch-title">{item.title}</p>
                                                <p className="lunch-description">{item.description}</p>
                                                <p className="lunch-group">Group: {item.group}</p>

                                            </div>
                                        </div>
                                    )
                                }
                                )
                                :

                                this.props.data.displayId === 4 ?
                                    this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                        return (
                                            <div key={item.id}
                                                onClick={() => {
                                                    this.props.showDetails(item.id)
                                                    this.props.setSelected(item.id, this.props.day.name)
                                                }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}
                                            >
                                                <div className="general-item-info">
                                                    <div className="activity-info">

                                                        <div className="info">
                                                            <p className="item-time">{item.time}</p>
                                                            <p className="item-title">{item.title}</p>
                                                        </div>

                                                        <div className="location">
                                                            <p>Place: {item.place}</p>
                                                            <p>Group: {item.group}</p>
                                                        </div>

                                                        <div className="item-teacher-info">
                                                            <div className="item-teacher-info-img">
                                                                <img src={item.teacher?.img} alt="" />
                                                            </div>
                                                            <div>
                                                                <p>{item.teacher?.category}</p>
                                                                <p>{item.teacher?.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    }
                                    ) : this.props.data[this.props.day.name][this.props.segment.name]?.map((item) => {
                                        return (
                                            <div key={item.id}
                                                onClick={() => {
                                                    this.props.showDetails(item.id)
                                                    this.props.setSelected(item.id, this.props.day.name)
                                                }} className={(this.props.selected === item.id && this.props.selectedDay === this.props.day.name) ? "segment-content-item selected-item" : "segment-content-item"}
                                            >
                                                <div className="shift-extra-data">
                                                    {item.extraData}
                                                </div>
                                                <div className="shift-info">

                                                    <div className="shift">
                                                        <div className="shift-img">
                                                            <img src={item.img} alt="" />
                                                        </div>
                                                        <div className="info">
                                                            <p className="info-name">{item.name}</p>
                                                            <p>{item.type}</p>
                                                            <p>{item.shift}</p>
                                                            <p>{item.apprentice}</p>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        )
                                    }
                                    )
                }
            </div>
        )
    }
}