import React, { Component } from 'react';
import {
  NavLink,
  Link, useLocation
} from "react-router-dom";
import logo from './LOGO.svg';
import smallLogo from './SMALL-LOGO.svg';

function Subcategories(props){
    const location = useLocation();

    if(location.pathname.startsWith("/calendar")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Calendar</h1>
          </div>
          <div id="subcategories">
            <div className="a-container">
          <NavLink to="/calendar/teachers" activeClassName="selected">Teachers</NavLink>
          <NavLink to="/calendar/lunch-menu" activeClassName="selected">Lunch menu</NavLink>
          <NavLink to="/calendar/activities" activeClassName="selected">Activities</NavLink>
          </div>
          </div>
          
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/documents")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Documents</h1>
          </div>
          <div id="subcategories">
            <div className="a-container">
          <NavLink to="/documents/overview" activeClassName="selected">Documents</NavLink>
          <NavLink to="/documents/invoices" activeClassName="selected">Invoices</NavLink>
          </div>
          </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/messages")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Messages</h1>
          </div>
          <div id="subcategories" className="dissapear-responsive">
        </div>
        </React.Fragment>
      );
    }
    if(location.pathname.startsWith("/child-info")){
      return(
        <React.Fragment>
          <div id="name">
            <h1>Child info</h1>
          </div><div id="subcategories" className="dissapear-responsive">
        </div></React.Fragment>
      );
    }
    
    return(
      <React.Fragment>
        <div id="name">
          <h1>Home</h1>
        </div>
        <div id="subcategories" className="dissapear-responsive">
        </div>
        </React.Fragment>
    );
}

class Header extends Component {
    state={
      account_container: false,
      search_container: false
    }
    
    
    render(){
      return (
        <React.Fragment>
            <header>
              <div id="logo">
                <img src={logo} alt="logo_wondergarden" className="large"/>
                <img src={smallLogo} alt="logo_wondergarden" className="small"/>
              </div>
              
              <Subcategories />
              <div id="my-account" >
                <div className="img-circle" onClick={() => this.setState({account_container: (this.state.account_container ? false : true)})}>
                  <img src="./assets/caretaker_2.png" alt="teacher"/>
                </div>
                <i className="material-icons">arrow_drop_down</i>
                <div className={this.state.account_container ? "my-account-container active" : "my-account-container"}>
                  <Link to="/"><button onClick={()=>this.setState({account_container: false})}><i className="material-icons">home</i> Go to Home</button></Link>
                  <button className="logout"><i className="material-icons">exit_to_app</i> Log out</button>
                </div>
              </div>
            </header>
        </React.Fragment>
      );
    }
  }
  
  export default Header;