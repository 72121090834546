import React, { Component } from 'react';
import {
    Link
} from "react-router-dom";

class CheckListForm extends Component {
    state = {
        dropdownOpened: false
    }

    setDoneChecklist = () => {
        this.setState({
            dropdownOpened: false
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="checklist">
                    <div className={this.props.answered ? "header complete" : "header"} onClick={() => this.setState({dropdownOpened: (this.state.dropdownOpened ? false : true)})}>
                        <h3>{this.props.order+'. '+this.props.name}</h3>
                        <i className="material-icons">{this.props.answered ? "check" : "keyboard_arrow_down"}</i>
                    </div>
                    <div className={this.state.dropdownOpened ? "card" : "card hidden"}>
                        <div className="flex">
                            <div className="flex-4 left column">
                                <div className="group-input column">
                                    <label htmlFor="">Test question 1</label>
                                    <p>Answer question 1 test</p>
                                </div>
                                <div className="group-input column">
                                <label htmlFor="">Test question 1</label>
                                    <p>Answer question 1 test</p>
                                </div>
                            </div>
                            <div className="flex-4 column">
                            <div className="group-input column">
                            <label htmlFor="">Test question 1</label>
                                    <p>Answer question 1 test</p>
                            </div>
                            <div className="group-input column">
                                    <label htmlFor="">Test question 1</label>
                                    <p>Answer question 1 test</p>
                            </div>
                                
                                
                            </div>
                            <div className="flex-4 right column">
                            <div className="group-input column">
                            <label htmlFor="">Test question 1</label>
                                    <p>Answer question 1 test</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default CheckListForm;