import React, { Component } from 'react';
import {
    Redirect
} from "react-router-dom";

class CalendarRoute extends Component {


    render() {
        return (
            <React.Fragment>
                <Redirect to="/calendar/teachers" />
            </React.Fragment>
        );
    }
}

export default CalendarRoute;